<template>
  <v-app>
    <v-card id="table-card">
      <v-card-title>
        Textblock
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="mr-2"
          v-on:click="Createtextblock"
          style="margin-top: 15px"
        >
          Create
        </v-btn>
        <v-btn
          depressed
          color="error"
          v-on:click="DeleteTextblock"
          style="margin-right: 15px; margin-top: 15px"
        >
          Delete
        </v-btn>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          textblock="Search"
          single-line
          hide-details
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        v-model="selected"
        show-select
        @click:row="EditRow"
      >
      </v-data-table>
    </v-card>
    <v-dialog v-model="createTextblockModal" persistent width="900px">
      <v-container class="grey lighten-5" fluid>
        <v-card>
          <v-card-title style="justify-content: center">
            <span class="headline">Create Textblock</span>
          </v-card-title>
          <v-cardtext>
            <v-text-field
              v-model="textblock.name"
              color="teal"
              textblock="name"
              label="textblock name"
            >
            </v-text-field>
            <v-textarea
              v-model="textblock.value"
              color="teal"
              textblock="value"
              rows="4"
              label="value1"
            >
            </v-textarea>
            <v-textarea
              v-model="textblock.value1"
              color="teal"
              textblock="value1"
              rows="4"
              label="value2"
            >
            </v-textarea>
            <v-textarea
              v-model="textblock.value2"
              acolor="teal"
              textblock="value2"
              rows="4"
              label="value3"
            >
            </v-textarea>
            <v-textarea
              v-model="textblock.value3"
              color="teal"
              textblock="value3"
              rows="4"
              label="value4"
            >
            </v-textarea>
            <v-textarea
              v-model="textblock.value4"
              color="teal"
              textblock="value4"
              rows="4"
              label="value5"
            >
            </v-textarea>
            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn
                  color="primary"
                  class="mr-2"
                  v-on:click="Createtextblock(textblock)"
                >
                  Save
                </v-btn>
                <v-btn color="primary" class="mr-2" v-on:click="Close">
                  Cancel
                </v-btn>
              </div>
            </v-layout>
          </v-cardtext>
        </v-card>
      </v-container>
    </v-dialog>

    <!-- Edit row -->
    <v-dialog v-model="editTextblockModal" persistent width="900px">
      <v-container class="grey lighten-5" fluid>
        <v-card>
          <v-card-title style="justify-content: center">
            <span class="headline">Edit Textblock</span>
          </v-card-title>

          <v-card-text>
            <!-- <v-text-field
              v-model="textblock.name"
              color="teal"
              textblock="name"
              label="textblock name"
            >
            </v-text-field> -->
            <v-textarea
              v-model="textblock.value"
              color="teal"
              textblock="value"
              rows="4"
              label="value1"
            >
            </v-textarea>
            <v-textarea
              v-model="textblock.value1"
              color="teal"
              textblock="value1"
              rows="4"
              label="value2"
            >
            </v-textarea>
            <v-textarea
              v-model="textblock.value2"
              acolor="teal"
              textblock="value2"
              rows="4"
              label="value3"
            >
            </v-textarea>
            <v-textarea
              v-model="textblock.value3"
              color="teal"
              textblock="value3"
              rows="4"
              label="value4"
            >
            </v-textarea>
            <v-textarea
              v-model="textblock.value4"
              color="teal"
              textblock="value4"
              rows="4"
              label="value5"
            >
            </v-textarea>
            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn
                  color="primary"
                  class="mr-2"
                  v-on:click="Update(textblock)"
                >
                  Update
                </v-btn>
                <v-btn color="primary" class="mr-2" v-on:click="Close">
                  Cancel
                </v-btn>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>
  </v-app>
</template>

<style>
</style>

<script>
const axios = require("axios").default;

export default {
  data: () => ({
    createTextblockModal: false,
    editTextblockModal: false,
    search: "",
    selected: [],
    textblock: {
      // id не нужен!
      name: "",
      value: "",
      value1: "",
      value2: "",
      value3: "",
      value4: "",
    },
    headers: [
      {
        text: "Text Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Value1", value: "value" },
      { text: "Value2", value: "value1" },
      { text: "Value3", value: "value2" },
      { text: "Value4", value: "value3" },
      { text: "Value5", value: "value4" },
    ],
    items: [],
    config: "",
  }),
  // created: function () {
  //   this.getConfig();
  // },
  async mounted() {
    await this.$store.dispatch("getConfig");
    this.config = await this.$store.getters.getConfig;
    let response = await axios.get(this.config + "/GetAllTextblocks");
    if (response.status == "200") {
      this.items = response.data;
      console.log(response.data, "Yes");
    } else {
      console.log(response.status, "No");
    }
  },

  methods: {
    // async getConfig() {
    //   await this.$store.dispatch("getConfig");
    //   this.config = await this.$store.getters.getConfig;
    //   return this.config
    // },
    EditRow(e) {
      console.log(e);
      this.editTextblockModal = true;
      if (e !== undefined) {
        this.textblock = {
          id: e.id,
          name: e.name,
          value: e.value,
          value1: e.value1,
          value2: e.value2,
          value3: e.value3,
          value4: e.value4,
        };
      }
    },

    getNameValue: function (key) {
      if (key !== undefined) {
        let name = this.items.find((x) => x.name === key);
        console.log(this.items);
        if (name !== undefined) {
          console.log(name["value" + this.langKey]);
          return name["value" + this.langKey];
        }
        return key;
      }
    },
    async getTextblock() {
      console.log("get textblock in Textblock");
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let response = await axios.get(this.config + "GetAllTextblocks");

      if (response.statusText == "200") {
        this.items = response.data;
        console.log(response.data, "Getted");
      } else {
        console.log(response.data, "Fail");
      }
    },
    async Update(item) {
      let responseData = false;
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      axios
        .post(this.config + "/UpdateTextblock", item)
        .then((response) => {
          alert(response.data ? "Update Succsess" : "Update error");
          responseData = response.data;
          this.Close();
          this.UpdateAfter(responseData, item);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    async UpdateAfter(responseData, item) {
      if (responseData) {
        let data = await this.getTextblockByName(item.name);
        let index = this.items.findIndex((x) => x.name == data.name);
        this.items.splice(index, 1);
        if (data) {
          this.items.push(data);
        }
      }
    },
    async Save(item) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let response = await axios.post(this.config + "/SaveTextblock", item);
      return response.data;
    },
    async Createtextblock(item) {
      if (!this.createTextblockModal) {
        this.createTextblockModal = true;
      } else {
        let response = await this.Save(item);

        if (response != null) {
          let data = await this.getTextblockByName(response);
          if (data) {
            this.items.push(data);
          }

          this.textblock = {
            name: "",
            value: "",
            value1: "",
            value2: "",
            value3: "",
          };
          this.Close();
        } else {
          alert("Save error");
        }
      }
    },
    async getTextblockByName(name) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let response = await axios.get(
        this.config + "/GetTextblock?name=" + name
      );
      return response.data;
    },
    async DeleteTextblock() {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      if (this.selected.length > 0) {
        let response = await axios.post(
          this.config + "/DeleteTextblock",
          this.selected
        );
        for (let key in response.data) {
          if (response.data[key]) {
            let index = this.items.findIndex(
              (x) => x.name === this.selected.name
            );
            this.items.splice(index, 1);
          }
        }
      }
    },
    Close() {
      this.createTextblockModal = false;
      this.editTextblockModal = false;
      this.textblock = {
        name: "",
        value: "",
        value1: "",
        value2: "",
        value3: "",
      };
    },
  },
};
</script>