<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-btn
          color="primary"
          class="mr-2"
          v-on:click="CreateLicenseblock"
          style="margin-top: 15px"
        >
          Create
        </v-btn>
        <v-btn
          depressed
          color="error"
          v-on:click="DeleteLicenseblock"
          style="margin-right: 15px; margin-top: 15px"
        >
          Delete
        </v-btn>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        v-model="selected"
        show-select
        :items-per-page="-1"
        @click:row="EditRow"
      >
        <template v-slot:[`item.image`]="props">
          <v-img
            :src="'data:image/jpeg;base64,' + props.item.image"
            class="grey darken-4"
            max-height="30"
            max-width="50"
            contain
          ></v-img>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="createLicenseblockModal" persistent width="900px">
      <v-container class="grey lighten-5">
        <v-card>
          <v-card-title style="justify-content: center">
            <span class="headline">Create Licenseblock</span>
          </v-card-title>
          <v-img
            :src="'data:image/jpeg;base64,' + licenseblock.image"
            class="grey darken-4"
            max-height="170"
            contain
          ></v-img>
          <v-card-text>
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an image"
              prepend-icon="mdi-camera"
              label="image"
              @change="Preview_image"
            ></v-file-input>
            <v-text-field
              v-model="licenseblock.name1"
              color="teal"
              label="name1"
            >
            </v-text-field>
            <v-text-field
              v-model="licenseblock.name2"
              color="teal"
              label="name2"
            >
            </v-text-field>
            <v-text-field
              v-model="licenseblock.name3"
              color="teal"
              label="name3"
            >
            </v-text-field>
            <v-text-field
              v-model="licenseblock.name4"
              color="teal"
              label="name4"
            >
            </v-text-field>
            <v-text-field
              v-model="licenseblock.name5"
              color="teal"
              label="name5"
            >
            </v-text-field>
            <v-textarea
              v-model="licenseblock.text1"
              acolor="teal"
              label="text1"
            >
            </v-textarea>
            <v-textarea
              v-model="licenseblock.text2"
              acolor="teal"
              label="text2"
            >
            </v-textarea>
            <v-textarea
              v-model="licenseblock.text3"
              acolor="teal"
              label="text3"
            >
            </v-textarea>
            <v-textarea
              v-model="licenseblock.text4"
              acolor="teal"
              label="text4"
            >
            </v-textarea>
            <v-textarea
              v-model="licenseblock.text5"
              acolor="teal"
              label="text5"
            >
            </v-textarea>
            <v-checkbox
              v-model="licenseblock.islicense"
              acolor="teal"
              label="license"
            >
            </v-checkbox>
            <v-checkbox
              v-model="licenseblock.issertif"
              acolor="teal"
              label="sertif"
            >
            </v-checkbox>
            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn
                  color="primary"
                  class="mr-2"
                  v-on:click="CreateLicenseblock(licenseblock)"
                >
                  Save
                </v-btn>
                <v-btn color="primary" class="mr-2" v-on:click="Close">
                  Cancel
                </v-btn>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>

    <!-- Edit Licenseblock -->
    <v-dialog v-model="editLicenseblockModal" persistent width="900px">
      <v-container class="grey lighten-5">
        <v-card>
          <v-card-title style="justify-content: center">
            <span class="headline">Edit Licenseblock</span>
          </v-card-title>
          <v-img
            :src="'data:image/jpeg;base64,' + licenseblock.image"
            class="grey darken-4"
            max-height="170"
            contain
          ></v-img>
          <v-card-text>
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an image"
              prepend-icon="mdi-camera"
              label="image"
              @change="Preview_image"
            ></v-file-input>
            <v-text-field
              v-model="licenseblock.name1"
              color="teal"
              label="name1"
            >
            </v-text-field>
            <v-text-field
              v-model="licenseblock.name2"
              color="teal"
              label="name2"
            >
            </v-text-field>
            <v-text-field
              v-model="licenseblock.name3"
              color="teal"
              label="name3"
            >
            </v-text-field>
            <v-text-field
              v-model="licenseblock.name4"
              color="teal"
              label="name4"
            >
            </v-text-field>
            <v-text-field
              v-model="licenseblock.name5"
              color="teal"
              label="name5"
            >
            </v-text-field>
            <v-textarea
              v-model="licenseblock.text1"
              acolor="teal"
              label="text1"
            >
            </v-textarea>
            <v-textarea
              v-model="licenseblock.text2"
              acolor="teal"
              label="text2"
            >
            </v-textarea>
            <v-textarea
              v-model="licenseblock.text3"
              acolor="teal"
              label="text3"
            >
            </v-textarea>
            <v-textarea
              v-model="licenseblock.text4"
              acolor="teal"
              label="text4"
            >
            </v-textarea>
            <v-textarea
              v-model="licenseblock.text5"
              acolor="teal"
              label="text5"
            >
            </v-textarea>
            <v-checkbox
              v-model="licenseblock.islicense"
              acolor="teal"
              label="license"
            >
            </v-checkbox>
            <v-checkbox
              v-model="licenseblock.issertif"
              acolor="teal"
              label="sertif"
            >
            </v-checkbox>
            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn
                  color="primary"
                  class="mr-2"
                  v-on:click="Update(licenseblock)"
                >
                  Update
                </v-btn>
                <v-btn color="primary" class="mr-2" v-on:click="Close">
                  Cancel
                </v-btn>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
const axios = require("axios").default;
export default {
  name: "Licenseblocks",
  data: () => ({
    selected: [],
    search: "",
    image: "",
    createLicenseblockModal: false,
    editLicenseblockModal: false,
    licenseblock: {
      // id: null,
      image: "",
      name1: "",
      name2: "",
      name3: "",
      name4: "",
      name5: "",
      text1: "",
      text2: "",
      text3: "",
      text4: "",
      text5: "",
      islicense: false,
      issertif: false,
    },

    headers: [
      {
        text: "Image",
        align: "start",
        sortable: false,
        value: "image",
      },
      {
        text: "Name1",
        align: "start",
        sortable: false,
        value: "name1",
      },
      {
        text: "Name2",
        align: "start",
        sortable: false,
        value: "name2",
      },
      {
        text: "Name3",
        align: "start",
        sortable: false,
        value: "name3",
      },
      {
        text: "Name4",
        align: "start",
        sortable: false,
        value: "name4",
      },
      {
        text: "Name5",
        align: "start",
        sortable: false,
        value: "name5",
      },
      {
        text: "text1",
        align: "start",
        sortable: false,
        value: "text1",
      },
      {
        text: "text2",
        align: "start",
        sortable: false,
        value: "text2",
      },
      {
        text: "text3",
        align: "start",
        sortable: false,
        value: "text3",
      },
      {
        text: "text4",
        align: "start",
        sortable: false,
        value: "text4",
      },
      {
        text: "text5",
        align: "start",
        sortable: false,
        value: "text5",
      },
      {
        text: "License?",
        align: "start",
        sortable: false,
        value: "islicense",
      },
      {
        text: "Sertificate?",
        align: "start",
        sortable: false,
        value: "issertif",
      },
    ],
    items: [],
    config: "",
  }),

  created: function () {
    this.getLicenseblock();
  },
  // mounted: {},
  methods: {
    EditRow(e) {
      this.editLicenseblockModal = true;
      this.licenseblock = {
        id: e.id,
        image: e.image,
        name1: e.name1,
        name2: e.name2,
        name3: e.name3,
        name4: e.name4,
        name5: e.name5,
        text1: e.text1,
        text2: e.text2,
        text3: e.text3,
        text4: e.text4,
        text5: e.text5,
        islicense: e.islicense,
        issertif: e.issertif,
      };
    },
    getNameValue: function (key) {
      if (key !== undefined) {
        let name = this.items.find((x) => x.name === key);
        console.log(this.items);
        if (name !== undefined) {
          console.log(name["value" + this.langKey]);
          return name["value" + this.langKey];
        }
        return key;
      }
    },
    async getLicenseblock() {
      await this.$store.dispatch("getAllLicenseblocks");
      this.items = await this.$store.getters.getLicenseblocks;
      // let response = await axios.get(
      //   "http://95.216.78.44:5542/Samupak/GetAlLicenseblock"
      // );
      // if (response.statusText === "OK") {
      //   this.items = response.data;
      //   this.editLicenseblockModal = false;
      // }
    },
    async Update(item) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let responseData = false;
      axios
        .post(this.config + "/UpdateLicenseblock", item)
        .then((response) => {
          alert(response.data ? "Update Succsess" : "Update error");
          responseData = response.data;
          this.Close();
          this.UpdateAfter(responseData, item);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    async UpdateAfter(responseData, item) {
      if (responseData) {
        let data = await this.getLicenseblockById(item.id);
        let index = this.items.findIndex((x) => x.id == item.id);
        this.items.splice(index, 1);
        if (data) {
          this.items.push(data);
        }
      }
    },
    async Save(item) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let response = await axios.post(this.config + "/SaveLicenseblock", item);
      return response.data;
    },
    async CreateLicenseblock(item) {
      if (!this.createLicenseblockModal) {
        this.createLicenseblockModal = true;
        this.image = "";
      } else {
        let response = await this.Save(item);

        if (response != null) {
          let data = await this.getLicenseblockById(response);
          if (data) {
            this.items.push(data);
          }

          this.licenseblock = {
            id: null,
            name1: "",
            image: [],
            date: "",
            text1: "",
            longtext1: "",
          };
          this.Close();
        } else {
          alert("Save error");
        }
      }
    },
    async getLicenseblockById(id) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let response = await axios.get(this.config + "/GetLicenseblock/" + id);
      return response.data;
    },
    async DeleteLicenseblock() {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      if (this.selected.length > 0) {
        let response = await axios.post(
          this.config + "/DeleteLicenseblock",
          this.selected
        );
        for (let key in response.data) {
          if (response.data[key]) {
            let index = this.items.findIndex((x) => x.id === key);
            console.log(index);
            console.log(this.items[index]);
            this.items.splice(index, 1);
          }
        }
      }
    },
    Close() {
      this.createLicenseblockModal = false;
      this.editLicenseblockModal = false;
      this.licenseblock = {
        id: null,
        image: "",
        name1: "",
        name2: "",
        name3: "",
        text1: "",
        text2: "",
        text3: "",
        islicense: false,
        issertif: false,
      };
    },
    async Preview_image(e) {
      this.image = e;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.licenseblock.image = e.target.result
          .replace("data:", "")
          .replace(/^.+,/, "");
      };
      reader.readAsDataURL(this.image);
    },
  },
};
</script>
<style>
#date {
  background-color: rgb(235, 235, 235);
  width: 100%;
}
</style>
