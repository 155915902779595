<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-btn
          color="primary"
          class="mr-2"
          v-on:click="CreatePictureblock"
          style="margin-top: 15px"
        >
          Create
        </v-btn>
        <v-btn
          depressed
          color="error"
          v-on:click="DeletePictureblock"
          style="margin-right: 15px; margin-top: 15px"
        >
          Delete
        </v-btn>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        v-model="selected"
        show-select
        :items-per-page="-1"
        @click:row="EditRow"
      >
        <!-- Avatar/Picture -->
        <template v-slot:[`item.picture`]="props">
          <!-- ? -->
          <v-img
            :src="'data:image/jpeg;base64,' + props.item.picture"
            class="grey darken-4"
            max-height="30"
            max-width="50"
            contain
          ></v-img>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="createPictureblockModal" persistent width="900px">
      <v-container class="grey lighten-5">
        <v-card>
          <v-card-title style="justify-content: center">
            <span class="headline">Create Pictureblock</span>
          </v-card-title>
          <v-img
            :src="'data:image/jpeg;base64,' + pictureblock.picture"
            class="grey darken-4"
            max-height="170"
            contain
          ></v-img>
          <v-card-text>
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an picture"
              prepend-icon="mdi-camera"
              label="Picture"
              @change="Preview_image"
            ></v-file-input>
            <v-text-field v-model="pictureblock.name" color="teal" label="name">
            </v-text-field>
            <v-text-field
              v-model="pictureblock.value0"
              color="teal"
              label="value0"
            >
            </v-text-field>
            <v-text-field
              v-model="pictureblock.value1"
              color="teal"
              label="value1"
            >
            </v-text-field>
            <v-text-field
              v-model="pictureblock.value2"
              color="teal"
              label="value2"
            >
            </v-text-field>
            <v-text-field
              v-model="pictureblock.value3"
              color="teal"
              label="value3"
            >
            </v-text-field>

            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn
                  color="primary"
                  class="mr-2"
                  v-on:click="CreatePictureblock(pictureblock)"
                >
                  Save
                </v-btn>
                <v-btn color="primary" class="mr-2" v-on:click="Close">
                  Cancel
                </v-btn>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>

    <!-- Edit Pictureblock -->
    <v-dialog v-model="editPictureblockModal" persistent width="900px">
      <v-container class="grey lighten-5">
        <v-card>
          <v-card-title style="justify-content: center">
            <span class="headline">Edit Pictureblock</span>
          </v-card-title>
          <v-img
            :src="'data:image/jpeg;base64,' + pictureblock.picture"
            class="grey darken-4"
            max-height="170"
            contain
          ></v-img>
          <v-card-text>
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an picture"
              prepend-icon="mdi-camera"
              label="Picture"
              @change="Preview_image"
            ></v-file-input>
            <!-- <v-text-field v-model="pictureblock.name" color="teal" label="name">
            </v-text-field> -->
            <v-text-field
              v-model="pictureblock.value0"
              color="teal"
              label="value0"
            >
            </v-text-field>
            <v-text-field
              v-model="pictureblock.value1"
              color="teal"
              label="value1"
            >
            </v-text-field>
            <v-text-field
              v-model="pictureblock.value2"
              color="teal"
              label="value2"
            >
            </v-text-field>
            <v-text-field
              v-model="pictureblock.value3"
              color="teal"
              label="value3"
            >
            </v-text-field>

            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn
                  color="primary"
                  class="mr-2"
                  v-on:click="Update(pictureblock)"
                >
                  Update
                </v-btn>
                <v-btn color="primary" class="mr-2" v-on:click="Close">
                  Cancel
                </v-btn>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
const axios = require("axios").default;

export default {
  value: "Pictureblock",
  data: () => ({
    selected: [],
    search: "",
    image: "",
    createPictureblockModal: false,
    editPictureblockModal: false,
    pictureblock: {
      // id: "",
      // He Нужен id
      picture: "",
      name: "",
      value0: "",
      value1: "",
      value2: "",
      value3: "",
    },
    headers: [
      {
        text: "Picture",
        align: "start",
        sortable: false,
        value: "picture",
      },
      {
        text: "Pictureblock Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "value1",
        align: "start",
        sortable: false,
        value: "value0",
      },
      {
        text: "value2",
        align: "start",
        sortable: false,
        value: "value1",
      },
      {
        text: "value3",
        align: "start",
        sortable: false,
        value: "value2",
      },
      {
        text: "value4",
        align: "start",
        sortable: false,
        value: "value3",
      },
    ],
    items: [],
    config: "",
  }),

  created: function () {
    this.getPictureblock();
  },
  methods: {
    EditRow(e) {
      this.editPictureblockModal = true;
      this.pictureblock = {
        id: e.id,
        picture: e.picture,
        name: e.name,
        value0: e.value0,
        value1: e.value1,
        value2: e.value2,
        value3: e.value3,
      };
    },
    getvalueValue: function (key) {
      if (key !== undefined) {
        let value = this.items.find((x) => x.value === key);
        console.log(this.items);
        if (value !== undefined) {
          console.log(value["value" + this.langKey]);
          return value["value" + this.langKey];
        }
        return key;
      }
    },
    async getPictureblock() {
      await this.$store.dispatch("getAllPictureblocks");

      this.items = await this.$store.getters.getPictureblocks;
      // let response = await axios.get(
      //   "http://localhost:5081/api/Webits/GetAllPictureblocks"
      // );
      // if (response.statusText === "OK") {
      //   this.items = response.data;
      //   this.editPictureblockModal = false;
      // }
    },
    async Update(item) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let responseData = false;
      axios
        .post(this.config + "/UpdatePictureblock", item)
        .then((response) => {
          alert(response.data ? "Update Succsess" : "Update error");
          responseData = response.data;
          this.Close();
          this.UpdateAfter(responseData, item);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    async UpdateAfter(responseData, item) {
      if (responseData) {
        let data = await this.getPictureblockById(item.id);
        let index = this.items.findIndex((x) => x.id == item.id);
        this.items.splice(index, 1);
        if (data) {
          this.items.push(data);
        }
      }
    },
    async Save(item) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let response = await axios.post(
        this.config + "/SavePictureblock",
        item
      );
      return response.data;
    },
    async CreatePictureblock(item) {
      if (!this.createPictureblockModal) {
        this.createPictureblockModal = true;
        this.image = "";
      } else {
        let response = await this.Save(item);
        this.Close();
        alert("Update your page");
        if (response != null) {
          let data = await this.getPictureblockById(response);
          if (data) {
            this.items.push(data);
          }

          this.pictureblock = {
            // id: null,
            picture: [],
            name: "",
            value0: "",
            value1: "",
            value2: "",
            value3: "",          
          };         
          this.Close();
        } else {
          
          alert("Save error");
        }
      }
    },
    async getPictureblockById(id) {
      let response = await axios.get(
        this.config + "/GetPictureblock/" + id
      );
      return response.data;
    },
    async DeletePictureblock() {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      if (this.selected.length > 0) {
        let response = await axios.post(
          this.config + "/DeletePictureblock",
          this.selected
        );
        for (let key in response.data) {
          if (response.data[key]) {
            let index = this.items.findIndex((x) => x.id === key);
            console.log(index);
            console.log(this.items[index]);
            this.items.splice(index, 1);
          }
        }
      }
    },
    Close() {
      this.createPictureblockModal = false;
      this.editPictureblockModal = false;
      this.pictureblock = {
        // id: null,
        picture: [],
        name: "",
        value0: "",
        value1: "",
        value2: "",
        value3: "",
      };
    },
    async Preview_image(e) {
      this.image = e;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.pictureblock.picture = e.target.result
          .replace("data:", "")
          .replace(/^.+,/, "");
      };
      reader.readAsDataURL(this.image);
    },
  },
};
</script>